// Parallax
.parallax-container {
  position: relative;
  overflow: hidden;
  height: 500px;

  .parallax {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    img {
      display: none;
      position: absolute;
      left: 50%;
      bottom: 0;
      min-width: 100%;
      min-height: 100%;
      transform: translate3d(0,0,0);
      transform: translateX(-50%);
    }
  }
}
