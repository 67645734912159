// page specific styling

#home {
  .orbit-figure {
    max-height: 30rem;
  }

  .orbit {
    margin-bottom: 5rem;
  }

  hr {
    margin: 3rem 0;

    @include breakpoint(medium) {
      margin: 4rem 0;
    }
  }
}

#portfolio {
  .card {
    .card-image {
      max-height: 15rem;
    }
  }
}
