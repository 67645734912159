@import "settings";
@import "~foundation-sites/scss/foundation";

@include foundation-everything;

@import "~motion-ui/src/motion-ui";
@include motion-ui-transitions;
@include motion-ui-animations;

@import "parallax";
@import "pages";
@import "fx";
@import "elements";

body {
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;

  header, footer {
    flex-shrink: 0;
  }

  main {
    flex: 1 0 auto;
  }
}

.header {
  @extend .grad-blue-rad;

  @include breakpoint(medium) {
    @include shadow;

    background-size: cover;
    background: url("/assets/img/Blue-Matrix-Code.png") center bottom;

    padding-bottom: 15rem;
    margin-bottom: -15rem;
  }

  .brand-logo img {
    height: $brand-logo-height;
  }

  .title-bar-title img {
    height: 2rem;
  }

  .menu:not(.submenu) {
    background-color: $topbar-menu-background;

    @include breakpoint(medium) {
      margin-top: $brand-logo-height / 2 - nth($dropdownmenu-padding, 1) * 2 - $dropdown-font-size / 2;

      > li > a {
        padding: nth($dropdownmenu-padding, 1) * 2 nth($dropdownmenu-padding, 2) * 1.5;
      }
      > li.is-dropdown-submenu-parent > a {
        padding-right: nth($dropdownmenu-padding, 2) * 2.5;

        &:after {
          margin-right: nth($dropdownmenu-padding, 2);
        }
      }
    }
  }
}

.content {
  @include breakpoint(small down) {
    margin-top: 1rem;
  }

  .grid-container {
    z-index: 200;
    > *:not(.no-bg) {
      background-color: $body-background;
      padding: 0 $topbar-padding;
    }
  }

  .cover {
    padding-left: 0;
    padding-right: 0;
    width: 100%;

    display: inline-block;
    max-height: 40rem;
    overflow: hidden;

    img {
      min-width: 100%;
      min-height: 100%;
    }
  }
}

