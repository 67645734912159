@mixin shadow {
  -webkit-box-shadow: 1px 0 2em rgba(0, 0, 0, 0.6), inset 0 0 2em rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 0 2em rgba(0, 0, 0, 0.6), inset 0 0 2em rgba(0, 0, 0, 0.2);
  box-shadow: 1px 0 2em rgba(0, 0, 0, 0.6), inset 0 0 2em rgba(0, 0, 0, 0.2);
}

.shadow {
  @include shadow;
}

.grad-blue-rad {
  background: #070a2d;
  background: -moz-radial-gradient(center, ellipse cover, #070a2d 4%, #000000 100%);
  background: -webkit-radial-gradient(center, ellipse cover, #070a2d 4%, #000000 100%);
  background: radial-gradient(ellipse at center, #070a2d 4%, #000000 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#070a2d', endColorstr='#000000', GradientType=1);
}
